<script>
import TablePage from '@/found/components/table_page';
import Vue from 'vue';
import FormPage from '../form/form';

export default {
  extends: TablePage,
  components: {
    FormPage,
  },
  data() {
    return {
      formShow: false,
      formConfig: {},
      modalConfig: {
        title: '新增',
        visible: false,
        type: 'Modal',
      },
    };
  },
  mounted() {
    this.getConfigList('counterfeiting-list');
  },

  methods: {
    // 控制按钮显示
    clickVisible({ row, val: { code } }) {
      const { approveStatus } = row;
      if (
        approveStatus
        && code === 'edit'
        && (approveStatus === '1' || approveStatus === '2')
      ) {
        return false;
      }
      return true;
    },
    modalClick({ val, row }) {
      const formPageArg = {
        drawerTitle: '',
        code: val.code, // 按钮对应code
        id: '', // 数据code
        formShow: true,
      };
      switch (val.code) {
        case 'add':
          formPageArg.drawerTitle = '新增';
          break;
        case 'view':
          formPageArg.drawerTitle = '查看';
          formPageArg.id = row.id;
          break;
        case 'edit':
          formPageArg.drawerTitle = '编辑';
          formPageArg.id = row.id;
          break;
        default:
          formPageArg.drawerTitle = '新增';
      }
      this.createFormPage(formPageArg);
    },
    createFormPage(arg) {
      const that = this;
      const Component = Vue.extend(FormPage);
      const vm = new Component({
        el: document.createElement('div'),
        data() {
          return {
            ...arg,
          };
        },
        methods: {
          getParentTable() {
            that.getConfigList('counterfeiting-list');
          },
        },
      });
      document.body.appendChild(vm.$el);
    },
  },
};
</script>
